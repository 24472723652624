exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-hr-packages-js": () => import("./../../../src/pages/hr-packages.js" /* webpackChunkName: "component---src-pages-hr-packages-js" */),
  "component---src-pages-hr-software-js": () => import("./../../../src/pages/hr-software.js" /* webpackChunkName: "component---src-pages-hr-software-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-hr-brighton-js": () => import("./../../../src/pages/locations/hr-brighton.js" /* webpackChunkName: "component---src-pages-locations-hr-brighton-js" */),
  "component---src-pages-locations-hr-worthing-js": () => import("./../../../src/pages/locations/hr-worthing.js" /* webpackChunkName: "component---src-pages-locations-hr-worthing-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-what-we-do-employee-relations-js": () => import("./../../../src/pages/what-we-do/employee-relations.js" /* webpackChunkName: "component---src-pages-what-we-do-employee-relations-js" */),
  "component---src-pages-what-we-do-index-js": () => import("./../../../src/pages/what-we-do/index.js" /* webpackChunkName: "component---src-pages-what-we-do-index-js" */),
  "component---src-pages-what-we-do-leadership-culture-and-development-js": () => import("./../../../src/pages/what-we-do/leadership-culture-and-development.js" /* webpackChunkName: "component---src-pages-what-we-do-leadership-culture-and-development-js" */),
  "component---src-pages-what-we-do-recruitment-and-software-js": () => import("./../../../src/pages/what-we-do/recruitment-and-software.js" /* webpackChunkName: "component---src-pages-what-we-do-recruitment-and-software-js" */),
  "component---src-pages-who-we-are-index-js": () => import("./../../../src/pages/who-we-are/index.js" /* webpackChunkName: "component---src-pages-who-we-are-index-js" */),
  "component---src-pages-who-we-are-our-purpose-and-values-js": () => import("./../../../src/pages/who-we-are/our-purpose-and-values.js" /* webpackChunkName: "component---src-pages-who-we-are-our-purpose-and-values-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

